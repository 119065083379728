import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Models() {

  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [editModel, setEditModel] = useState(null)

  const addModel = () => {
    setOpen(true);
    product.models.push({});
    setEditModel(+(product.models.length - 1))
  }

  const editModelClick = (model) => {
    setOpen(true)
    setEditModel(product.models.indexOf(model));
  }

  const updateInEdit = (field, val) => {
    setProduct({ ...product, models: [...product.models.map((x, i) => i == editModel ? { ...x, [field]: val } : x)] });
  }

  const save = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      navigate("/product/" + params.id)
    })
  }

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setLoaded(true);
        setProduct(x.product);
      })
    }
    fetchData();
  }, [])

  if (!loaded) return <></>
  return (
    <>
      <Modal models={product.models.filter(x => x.name).map(x => x.name)} open={editModel !== null} close={() => setEditModel(null)} model={product.models[editModel]} update={(field, val) => updateInEdit(field, val)}></Modal>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="bg-white p-6">
            <div className="sm:items-center">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div>
                      <span
                        onClick={() => addModel()}
                        className="cursor-pointer float-right ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      >
                        Add Data Model
                      </span>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Data Models</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Data models are the structure of all the data you need to collect. Think of each 'object' you need to collect data on and add them all here. It can help to think of data models as physical things; for example you could add a data model for a type of person, a car, a building or a book.
                      </p>
                    </div>

                    <div className="px-4 sm:px-6 lg:px-8">
                      <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                      Name
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {product.models.map((model) => (
                                    <tr key={model._id}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div className="flex items-center">
                                          <div className="ml-4">
                                            <div className="font-medium text-gray-900">{model.name}</div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        {!model.readOnly && <span onClick={() => editModelClick(model)} className="cursor-pointer text-green-600 hover:text-green-900">
                                          Edit
                                        </span>}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => save()}
                      type="button"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



function Modal(props) {
  if (!props.model) return <></>
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transdiv overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Model
                    </Dialog.Title>
                    <div className="mt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Name
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="flex max-w-lg rounded-md shadow-sm">
                          <input
                            value={props.model.name}
                            onChange={(e) => props.update('name', e.target.value)}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Description
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="flex max-w-lg rounded-md shadow-sm">
                          <textarea
                            value={props.model.description}
                            onChange={(e) => props.update('description', e.target.value)}
                            className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Belongs To User
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="flex max-w-lg rounded-md">
                          <input type="checkbox"
                            checked={props.model.belongsToUser}
                            onChange={(e) => props.update('belongsToSingleUser', e.target.checked)}
                            className="block rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label className="block text-xl text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Fields
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="grid grid-cols-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Field Name
                          </label>
                          <label className="block text-sm font-medium text-gray-700">
                            Type
                          </label>
                        </div>
                        {props.model.fields && props.model.fields.map(x => <div className="grid grid-cols-2 gap-1 mb-1">


                          <input
                            value={x.name}
                            onChange={(e) => props.update('fields', [...props.model.fields.map(y => y === x ? { ...x, name: e.target.value } : y)])}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          />

                          <select
                            value={x.type}
                            onChange={(e) => props.update('fields', [...props.model.fields.map(y => y === x ? { ...x, type: e.target.value } : y)])}
                            className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          >
                            <option value="Text">Text</option>
                            <option value="Number">Number</option>
                            <option value="Date">Date</option>
                            <option value="Boolean">Boolean</option>
                            {props.models.map(x => <>
                              <option value={`List_${x}`}>List Of {x}</option>
                              <option value={`Ref_${x}`}>One {x}</option>
                            </>)}
                          </select>

                        </div>)}
                        <br />
                        <button
                          onClick={() => props.update('fields', [...(props.model.fields ? props.model.fields : []), { name: '' }])}
                          type="button"
                          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Add Field
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => props.close()}
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

