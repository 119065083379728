import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFetch from './hooks/useFetch';
import logo from './logo.png'

export default function Login() {

  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleGoogle, loading, err } = useFetch(
    `${process.env.REACT_APP_API_URL}/auth/login-google`
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      window.google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        // type: "standard",
        theme: "outline",
        // size: "small",
        text: "signin_with",
      });

      // google.accounts.id.prompt()
    }
  }, [handleGoogle]);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values, invitedByCode: searchParams.get("i") })
    }).then(res => { return res.json() }).then(x => {
      if (x.token) {
        localStorage.setItem("token", x.token);
        window.location.replace("/")
      }
      else {
        setError("Login failed. Please check your details and try again.")
      }
    })
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-center text-3xl font-bold tracking-tight text-gray-900">Login</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <a href="/register" className="font-medium text-green-500 hover:text-green-500">
              register for an account
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 text-center">
                  {error}
                </label>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">


                <div className="text-sm">
                  <a href="/request-reset" className="font-medium text-purple-600 hover:text-purple-500">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  onClick={() => submit()}
                  type="button"
                  className="flex w-full justify-center rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="text-sm text-neutral-600 text-center my-3">or</div>
            <div className="flex">
              {err && <p style={{ color: "red" }}>{err}</p>}
              <div className="mx-auto" id="loginDiv"></div>


            </div>

          </div>

        </div>
      </div>
    </>
  )
}
