import { ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useCallback } from "react"
import { useHookstate } from '@hookstate/core';
import { MenuItem, useMenuState, ControlledMenu, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { v4 as uuid } from 'uuid';
import Button from './button'
import { useNavigate } from "react-router-dom";
import { parse } from 'node-html-parser';
import { Accordion } from "flowbite-react";
import Editor, { useMonaco } from "@monaco-editor/react";
import { debounce } from "lodash";

export default function Designer() {

    const [currentElementId, setCurrentElementId] = useState();
    const navigate = useNavigate();
    const state = useHookstate({ elements: [] })
    const [page, setPage] = useState(null);
    const [open, setOpen] = useState(false)
    const [html, setHtml] = useState("");
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [isComponent, setIsComponent] = useState(false);
    const [saveState, setSaveState] = useState(0);
    const [pages, setPages] = useState([]);
    const [components, setComponents] = useState([]);
    const [altIndex, setAltIndex] = useState(false);

    const [viewStyle, setViewStyle] = useState("visual")

    const monaco = useMonaco();

    const setEditorHtml = () => {
        setHtml(getHtmlContent());
    }

    function handleEditorDidMount(editor, monaco) {
        editor.trigger('', 'editor.action.formatDocument')
    }

    const updateHtml = () => {
        state.elements.set([MapHtmlNodeToElement(parse(html).firstChild)])
    }

    useEffect(() => {
        async function fetchData() {
            await fetch(`${process.env.REACT_APP_API_URL}/page`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token")
                })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                setPages(x);
                setPage(x[0])
                setIsComponent(false);
                state.elements.set(x[0].elements ?? []);
                setViewStyle('visual')
            })
            await fetch(`${process.env.REACT_APP_API_URL}/component`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token")
                })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                setComponents(x);
            })
        }
        fetchData();
    }, [])

    const loadPage = async (id) => {
        await fetch(`${process.env.REACT_APP_API_URL}/page/${id}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("token")
            })
        }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
            setIsComponent(false);
            setPage(x);
            state.elements.set(x.elements ?? []);
            setViewStyle('visual')
        })
    }

    const loadComponent = async (id) => {
        await fetch(`${process.env.REACT_APP_API_URL}/component/${id}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("token")
            })
        }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
            setIsComponent(true);
            setPage(x);
            state.elements.set(x.elements ?? []);
            setViewStyle('visual')
        })
    }

    const addPage = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/page/add`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("token")
            })
        }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
            setPages(x);
        })
    }

    const addComponent = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/component/add`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("token")
            })
        }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
            setComponents(x);
        })
    }

    const save = async (data, isComponent) => {
        if (!isComponent) {
            await fetch(`${process.env.REACT_APP_API_URL}/page`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ page: data })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                setSaveState(0)
            })
        }
        else {
            await fetch(`${process.env.REACT_APP_API_URL}/component`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ component: data })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                setSaveState(0)
            })
        }
    }

    const debouncedSave = useCallback(
        debounce(async (page, isComponent) => {
            setSaveState(1)
            save(page, isComponent);
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedSave(page, isComponent)
    }, [page]);

    useEffect(() => {
        if (altIndex !== false) {
            setPage({ ...page, alts: page.alts.map((x, i) => i === altIndex ? JSON.parse(JSON.stringify(state.elements.value)) : x) })
        }
        else {
            setPage({ ...page, elements: JSON.parse(JSON.stringify(state.elements.value)) })
        }
    }, [state.elements])

    useEffect(() => {
        if (page) {
            if (altIndex !== false) {
                state.elements.set(JSON.parse(JSON.stringify(page.alts[altIndex] ?? [])));
            }
            else {
                state.elements.set(JSON.parse(JSON.stringify(page.elements ?? [])));
            }
        }
    }, [altIndex])

    const getHtmlContent = () => {
        let html = "";
        for (let n of state.elements.value) {
            html += getHtml(n);
        }
        return html
    }

    const getHtml = (element) => {
        if (!element) return ''
        if (!element.element && element.content) return `${element.content}`

        let svgAttrs = ["xmlns", "fill", "viewBox", "stroke-width", "stroke", "stroke-linecap", "stroke-linejoin", "d", "placeholder", "af_type_index", "type", "af_type_count", "rows"];
        let svgElements = " ";
        for (let attr of svgAttrs) {
            if (element[attr]) {
                svgElements += ` ${attr}="${element[attr]}"`
            }
        }

        if (!isComponent) {
            svgElements += ` id="${element.id}"`
        }

        let htmlString = `<${element.element ?? 'div'}${svgElements}${element.className ? ` class="${element.className}"` : ''}${element.allowLink ? ' allowLink' : ''}${element.backButton ? ' backButton' : ''}${element.isArray ? ' isArray' : ''}${element.editableTextarea ? ' editableTextarea' : ''}${element.primary_text ? ' pt' : ''}${element.secondary_text ? ' st' : ''}${element.tertiary_text ? ' tt' : ''}${element.primary_bg ? ' pb' : ''}${element.secondary_bg ? ' sb' : ''}${element.tertiary_bg ? ' tb' : ''}${element.isLogo ? ' logo' : ''}${element.imgContent ? ' imgContent="' + element.imgContent + '"' : ''}>`
        if (element.content) {
            htmlString += `${element.content}`
        }
        if (element.children) {
            for (let child of element.children) {
                htmlString += `${getHtml(child)}`
            }
        }

        htmlString += `</${element.element ?? 'div'}>`

        return htmlString
    }

    const pageGroups =
        pages.reduce((group, page) => {
            const { category } = page;
            group[category] = group[category] ?? [];
            group[category].push(page);
            return group;
        }, {});



    return <>
        <div className="h-screen text-left bg-gray-100">

            <div className="flex-1 flex flex-col h-screen max-h-screen">
                <div className="bg-gray-800 shadow-purple-500 shadow h-14">
                    {saveState === 0 && <span className="absolute top-1 right-1 text-gray-800 text-xs">Saved</span>}
                    {saveState === 1 && <span className="absolute top-1 right-1 text-gray-800 text-xs">Saving...</span>}
                    {viewStyle === "visual" && <Button text="Html Editor" className="mt-2 ml-2" onClick={() => { setViewStyle("html"); setEditorHtml(getHtmlContent()) }}></Button>}
                    {viewStyle === "html" && <Button text="Visual Editor" className="mt-2 ml-2" onClick={() => { updateHtml(); setViewStyle("visual") }}></Button>}

                    {viewStyle === "visual" && <Button text="Add Alt" className="mt-2 ml-2" onClick={() => { setPage({ ...page, alts: page.alts ? page.alts.concat([page.elements]) : [page.elements] }) }}></Button>}

                    <div className="inline-flex"><div onClick={() => { setAltIndex(false); }} className={`${altIndex === false ? 'bg-green-500 text-white' : 'bg-white'}  p-1 rounded shadow m-1 ml-2 px-5`}>Default</div> {page?.alts?.map((x, i) => <div onClick={() => { setAltIndex(i); }} className={`${i === altIndex ? 'bg-green-500 text-white' : 'bg-white'}  p-1 rounded shadow m-1 ml-2 px-5`}>{i}</div>)}
                    </div>
                </div>
                <div className="flex-1 flex max-h-full">

                    <div className="w-96 bg-white shadow ">

                        <Accordion alwaysOpen={true} flush={true}>
                            <Accordion.Panel>
                                <Accordion.Title>
                                    Page Templates
                                </Accordion.Title>
                                <Accordion.Content>
                                    <div className="max-h-96 overflow-auto text-sm">
                                        {Object.keys(pageGroups)?.map(g => <div>
                                            {g}
                                            {pageGroups[g].map(x => <div onClick={() => loadPage(x._id)} className={`${page?._id === x._id ? 'bg-green-400' : 'bg-gray-50 hover:bg-gray-100'} cursor-pointer text-gray-800 text-xs border border-gray-200 p-1`}>{x.name}</div>)}
                                        </div>)}

                                        <div onClick={() => addPage()} className={`mt-3 cursor-pointer text-gray-800  border border-gray-200 bg-gray-50 hover:bg-gray-100`}>Add Page</div>
                                    </div>
                                </Accordion.Content>
                            </Accordion.Panel>
                            <Accordion.Panel>
                                <Accordion.Title>
                                    Components
                                </Accordion.Title>
                                <Accordion.Content>
                                    <div className="max-h-44 overflow-auto text-sm">
                                        {components.map(x => <div onClick={() => loadComponent(x._id)} className={`${page?._id === x._id ? 'bg-green-400' : 'bg-gray-50 hover:bg-gray-100'} cursor-pointer text-gray-800  border border-gray-200 p-2`}>{x.name}</div>)}
                                        <div onClick={() => addComponent()} className={`mt-3 cursor-pointer text-gray-800  border border-gray-200 bg-gray-50 hover:bg-gray-100`}>Add Component</div>
                                    </div>

                                </Accordion.Content>
                            </Accordion.Panel>
                        </Accordion>

                    </div>
                    <div className="flex-1 flex flex-col bg-gray-300 ">
                        {viewStyle === "visual" && <div className="flex-1 flex flex-col max-h-full">
                            <div className="flex-1 pt-10">
                                <div className="w-[28rem] shadow-lg bg-white mx-auto h-[80vh] overflow-y-auto overflow-x-hidden hide-scroll">
                                    {page && <ElementList components={components} isComponent={isComponent} propagateBreadcrumb={(data) => setBreadcrumb(data)} currentElementId={currentElementId} close={() => setCurrentElementId(null)} open={(id) => { setCurrentElementId(id); }} nodes={state.elements}></ElementList>}
                                </div>
                            </div>

                            <div className="h-10 bg-gray-50 text-left">
                                {currentElementId && <div className="m-2">
                                    {breadcrumb.map((x, i) => <div onClick={() => setCurrentElementId(x.id)} className="inline text-gray-400 cursor-pointer group ml-2"><span className="group-hover:text-green-600 ">{x.ele ?? 'div'}</span> {i + 1 < breadcrumb.length && <ChevronRightIcon className=" inline h-4 w-4"></ChevronRightIcon>}</div>)}
                                </div>}
                            </div>
                        </div>}
                        {viewStyle === "html" && <>
                            <Editor
                                onMount={handleEditorDidMount}
                                height="100%"
                                theme="vs-dark"
                                defaultLanguage="html"
                                value={html}
                                onChange={(value) => setHtml(value)}
                            />
                        </>}
                    </div>
                    {viewStyle === "visual" && <div className="w-96 bg-white shadow">
                        {!currentElementId && <>
                            {!isComponent && <Accordion alwaysOpen={true} flush={true}>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        Page Settings
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <span>Category</span>
                                        <input
                                            key={page?._id + 'c'}
                                            value={page?.category}
                                            onChange={(e) => setPage({ ...page, category: e.target.value })}
                                            type="text"
                                            className="w-full block min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                        <span>Name</span>
                                        <input
                                            key={page?._id + 'n'}
                                            value={page?.name}
                                            onChange={(e) => setPage({ ...page, name: e.target.value })}
                                            type="text"
                                            className="w-full block min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                        <span>Description</span>
                                        <input
                                            key={page?._id + 'd'}
                                            value={page?.description}
                                            onChange={(e) => setPage({ ...page, description: e.target.value })}
                                            type="text"
                                            className="w-full block min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />

                                    </Accordion.Content>
                                </Accordion.Panel>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        Variables
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        {page?.variables?.map(x => <>
                                            <div className="relative"><TrashIcon onClick={() => setPage({ ...page, variables: page.variables.filter(y => y !== x) })} className="cursor-pointer w-4 h-4 text-gray-400 hover:text-gray-500 absolute -right-4 top-2"></TrashIcon></div>
                                            <div className="grid grid-cols-2 gap-1 mt-2">
                                                <input
                                                    value={x?.name}
                                                    onChange={(e) => setPage({ ...page, variables: page.variables.map(y => y === x ? { ...y, name: e.target.value } : y) })}
                                                    type="text"
                                                    placeholder="Variable Name"
                                                    className="block min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                />
                                                <input
                                                    value={x?.default}
                                                    onChange={(e) => setPage({ ...page, variables: page.variables.map(y => y === x ? { ...y, default: e.target.value } : y) })}
                                                    type="text"
                                                    placeholder="Default Value"
                                                    className="block min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                />
                                                <div className="col-span-2 flex">
                                                    <input
                                                        value={x?.prompt}
                                                        onChange={(e) => setPage({ ...page, variables: page.variables.map(y => y === x ? { ...y, prompt: e.target.value } : y) })}
                                                        type="text"
                                                        placeholder="Prompt"
                                                        className="flex-1 block min-w-0 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                    />
                                                    <input type="number" value={x?.max}
                                                        onChange={(e) => setPage({ ...page, variables: page.variables.map(y => y === x ? { ...y, max: e.target.value } : y) })}
                                                        placeholder="max"
                                                        className="w-24 ml-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"></input>
                                                </div>

                                                <hr className="col-span-2 mt-1" />
                                            </div>
                                        </>)}
                                        <div onClick={() => setPage({ ...page, variables: page.variables.concat([{}]) })} className={`bg-gray-50 hover:bg-gray-100 mt-2 cursor-pointer text-gray-800  border border-gray-200 p-2`}>Add</div>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>}

                            {isComponent && <Accordion alwaysOpen={true} flush={true}>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        Component Settings
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <span>Name</span>
                                        <input
                                            value={page?.name}
                                            onChange={(e) => setPage({ ...page, name: e.target.value })}
                                            type="text"
                                            className="w-full block min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>}
                        </>}
                    </div>}
                </div>
            </div>

        </div >
    </>

}

function ElementList(props) {
    const state = useHookstate(props.nodes);

    return <>
        {state.ornull && state.ornull.map((element, i) => {
            return <Element components={props.components} isComponent={props.isComponent} propagateBreadcrumb={props.propagateBreadcrumb} parent={props.parent} close={props.close} currentElementId={props.currentElementId} key={i} open={(ele) => props.open(ele)} element={element} i={i}></Element>
        }
        )}
    </>
}

const Element = ({ element, i, open, currentElementId, close, parent, propagateBreadcrumb, isComponent, components, move }) => {
    const [menuProps, toggleMenu] = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [isHover, setIsHover] = useState(false);

    let ele = useHookstate(element)
    if (!ele.get()) return null;
    let CustomElement = ele.element.get() ? ele.element.get() : 'div';
    let isVoid = CustomElement == "img" || CustomElement == "input"|| CustomElement == "textarea";
    if (CustomElement === "button") CustomElement = "div"


    let attrs = ["xmlns", "fill", "viewBox", "stroke-width", "stroke", "stroke-linecap", "stroke-linejoin", "d", "placeholder", "type", "rows"];
    let attrsObj = {};
    for (let attr of attrs) {
        if (ele.get()[attr]) {
            attrsObj[attr] = ele.get()[attr];
        }
    }

    if (isVoid) {
        return <>
            <CustomElement
                {...attrsObj}
                onMouseOver={(e) => { e.stopPropagation(); setIsHover(true) }}
                onMouseOut={(e) => { e.stopPropagation(); setIsHover(false) }}
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAnchorPoint({ x: e.clientX, y: e.clientY });
                    toggleMenu(true);
                }}
                key={i} onClick={(e) => { e.stopPropagation(); propagateBreadcrumb([{ id: ele.id.get(), ele: ele.element.get() }]); if (currentElementId !== ele.id.get()) { open(ele.id.get()) } else { close() } }}
                className={'cursor-default ' + ele.className.get() + (currentElementId === ele.id.get() ? ' bg-blue-200 inside-box-shadow' : '') + (isHover ? ' inside-box-shadow bg-blue-50' : ' ')} />

            {currentElementId === ele.id.get() && <>

                <ElementEditor element={ele} isComponent={isComponent}></ElementEditor>

            </>}
        </>
    }

    return <>
        <CustomElement
            {...attrsObj}
            onMouseOver={(e) => { e.stopPropagation(); setIsHover(true) }}
            onMouseOut={(e) => { setIsHover(false) }}
            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                setAnchorPoint({ x: e.clientX, y: e.clientY });
                toggleMenu(true);
            }}
            key={i} onClick={(e) => { e.stopPropagation(); propagateBreadcrumb([{ id: ele.id.get(), ele: ele.element.get() }]); if (currentElementId !== ele.id.get()) { open(ele.id.get()) } else { close() } }}
            className={'cursor-default ' + ele.className.get() + (currentElementId === ele.id.get() ? ' bg-blue-100 inside-box-shadow' : '') + (isHover ? ' inside-box-shadow bg-blue-50' : ' ')}>
            {ele.content.get()}
            <ElementList components={components} isComponent={isComponent} propagateBreadcrumb={(d) => propagateBreadcrumb([{ id: ele.id.get(), ele: ele.element.get() }].concat(d))} close={close} currentElementId={currentElementId} open={open} nodes={ele.children} parent={ele.get()} />

            {/* {isHover && <div className="relative w-0 left-[100%]"><div className="absolute p-2 bg-green-100 -right-5 text-sm font-normal text-gray-800 flex">
                <ArrowUpIcon onClick={() => move('up', i)} className="w-4 h-4 cursor-pointer m-1"></ArrowUpIcon>
                <ArrowDownIcon onClick={() => move('down', i)} className="w-4 h-4 cursor-pointer m-1"></ArrowDownIcon>
            </div></div>} */}

            {currentElementId === ele.id.get() && <>

                <ElementEditor isComponent={isComponent} element={ele}></ElementEditor>

            </>}
        </CustomElement>


        <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
            direction="right" onClose={() => toggleMenu(false)}
        >
            <SubMenu label="Container">
                <MenuItem onClick={() => { ele.children.set(x => (x || []).concat([{ id: uuid(), className: 'flex h-12' }])); toggleMenu(false) }}>Flex</MenuItem>
                <MenuItem onClick={() => { ele.children.set(x => (x || []).concat([{ id: uuid(), className: 'grid grid-cols-2 h-12' }])); toggleMenu(false) }}>Grid (2 Col)</MenuItem>
                <MenuItem onClick={() => { ele.children.set(x => (x || []).concat([{ id: uuid(), className: 'grid grid-cols-4 h-12' }])); toggleMenu(false) }}>Grid (4 Col)</MenuItem>
            </SubMenu>
            <SubMenu label="Components">
                {components?.map(y => <MenuItem onClick={() => { ele.children.set(x => (x || []).concat(y.elements.map(z => { return { ...z, id: uuid() } }))); toggleMenu(false) }}>{y.name}</MenuItem>)}


            </SubMenu>
        </ControlledMenu>


    </>
}

const ElementEditor = ({ element, isComponent }) => {

    return <>

        <div className="text-left fixed h-screen w-96 right-0 top-14 p-3 text-black opacity-100 text-md font-normal" onClick={(e) => e.stopPropagation()}>
            {!isComponent && <Accordion alwaysOpen={true} flush={true}>
                <Accordion.Panel>
                    <Accordion.Title>
                        Element
                    </Accordion.Title>
                    <Accordion.Content>

                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Element
                            </label>
                            <div className="flex max-w-lg rounded-md shadow-sm">
                                <select value={element.get().element}
                                    onChange={(e) => element.element.set(e.target.value)} className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                    <option value="">content</option>
                                    <option>div</option>
                                    <option>body</option>
                                    <option>span</option>
                                    <option>h1</option>
                                    <option>h2</option>
                                    <option>h3</option>
                                    <option>p</option>
                                    <option>img</option>
                                    <option>input</option>
                                    <option>svg</option>
                                    <option>path</option>
                                </select>
                            </div>
                        </div>

                        <label className="block text-sm font-medium text-gray-700">
                            Class
                        </label>
                        <div className="mt-1">
                            <textarea
                                value={element.get().className}
                                onChange={(e) => element.className.set(e.target.value)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                rows={4}
                            ></textarea>
                        </div>

                        {element.element.get() !== "img" && element.element.get() !== "input" && <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Content
                            </label>
                            <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                    value={element.get().content}
                                    onChange={(e) => element.content.set(e.target.value)}
                                    type="text"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>}

                        {element.element.get() === "img" && element.element.get() !== "input" && <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Image Content
                            </label>
                            <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                    value={element.get().imgContent}
                                    onChange={(e) => element.imgContent.set(e.target.value)}
                                    type="text"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>}

                        {element.element.get() !== "img" && element.content.get() && <div className="mt-3">
                            <label htmlFor="name" className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Edit Content As Textarea
                            </label>
                            <div className="ml-2 inline max-w-lg rounded-md">
                                <input
                                    checked={element.get().editableTextarea}
                                    onChange={(e) => element.editableTextarea.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>}

                        {element.element.get() === "img" && <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className=" text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Logo
                            </label>
                            <div className="ml-2 inline max-w-lg rounded-md">
                                <input
                                    checked={element.get().isLogo}
                                    onChange={(e) => element.isLogo.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>}
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        Colours
                    </Accordion.Title>
                    <Accordion.Content>
                        <div className="grid grid-cols-3 text-center">
                            <div className="border-r border-gray-200"></div>
                            <div className="border-r border-gray-200 block text-sm font-medium text-gray-700">Background</div>
                            <div className="border-r border-gray-200 block text-sm font-medium text-gray-700">Text</div>
                            <div className="border-r border-gray-200 block text-sm font-medium text-gray-700 p-3">Primary</div>
                            <div className="border-r p-3">
                                <input
                                    checked={element.get().primary_bg}
                                    onChange={(e) => element.primary_bg.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                            <div className="border-r p-3">
                                <input
                                    checked={element.get().primary_text}
                                    onChange={(e) => element.primary_text.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>

                            <div className="border-r border-gray-200 block text-sm font-medium text-gray-700 p-3">Secondary</div>
                            <div className="border-r p-3">
                                <input
                                    checked={element.get().secondary_bg}
                                    onChange={(e) => element.secondary_bg.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                            <div className="border-r p-3">
                                <input
                                    checked={element.get().secondary_text}
                                    onChange={(e) => element.secondary_text.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>

                            <div className="border-r border-gray-200 block text-sm font-medium text-gray-700 p-3">Tertiary</div>
                            <div className="border-r p-3">
                                <input
                                    checked={element.get().tertiary_bg}
                                    onChange={(e) => element.tertiary_bg.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                            <div className="border-r p-3">
                                <input
                                    checked={element.get().tertiary_text}
                                    onChange={(e) => element.tertiary_text.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 mx-auto rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>

                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        Settings
                    </Accordion.Title>
                    <Accordion.Content>

                        <div>
                            <label htmlFor="name" className=" text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Allow Link
                            </label>
                            <div className="ml-2 max-w-lg rounded-md inline">
                                <input
                                    checked={element.get().allowLink}
                                    onChange={(e) => element.allowLink.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="name" className=" text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Back Button
                            </label>
                            <div className="ml-2 max-w-lg rounded-md inline">
                                <input
                                    checked={element.get().backButton}
                                    onChange={(e) => element.backButton.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="name" className=" text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Is Array Element
                            </label>
                            <div className="ml-2 max-w-lg rounded-md inline">
                                <input
                                    checked={element.get().isArray}
                                    onChange={(e) => element.isArray.set(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <TrashIcon className="mt-3 w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => element.set(undefined)}></TrashIcon>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>}

            {isComponent && <Accordion alwaysOpen={true} flush={true}>
                <Accordion.Panel>
                    <Accordion.Title>
                        Element
                    </Accordion.Title>
                    <Accordion.Content>

                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Element
                            </label>
                            <div className="flex max-w-lg rounded-md shadow-sm">
                                <select value={element.get().element}
                                    onChange={(e) => element.element.set(e.target.value)} className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                    <option>div</option>
                                    <option>body</option>
                                    <option>span</option>
                                    <option>h1</option>
                                    <option>h2</option>
                                    <option>h3</option>
                                    <option>p</option>
                                    <option>img</option>
                                    <option>input</option>
                                </select>
                            </div>
                        </div>

                        <label className="block text-sm font-medium text-gray-700">
                            Class
                        </label>
                        <div className="mt-1">
                            <textarea
                                value={element.get().className}
                                onChange={(e) => element.className.set(e.target.value)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                rows={4}
                            ></textarea>
                        </div>

                        {element.element.get() !== "img" && element.element.get() !== "input" && <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Content
                            </label>
                            <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                    value={element.get().content}
                                    onChange={(e) => element.content.set(e.target.value)}
                                    type="text"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>}

                        {element.element.get() === "img" && element.element.get() !== "input" && <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Image Content
                            </label>
                            <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                    value={element.get().imgContent}
                                    onChange={(e) => element.imgContent.set(e.target.value)}
                                    type="text"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                />
                            </div>
                        </div>}
                        <TrashIcon className="mt-3 w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => element.set(undefined)}></TrashIcon>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>}
        </div>
    </>
}

const MapHtmlNodeToElement = (node) => {
    let content = node._rawText;
    if (node.childNodes && node.childNodes.length === 1 && !node.childNodes[0].rawTagName) {
        content = node.childNodes[0]._rawText;
    }
    content = content?.replace(/\s+/g, ' ').trim()

    let obj = { id: node?.attributes?.id ? node?.attributes?.id : uuid(), element: node.rawTagName, content: content, className: node.classNames, children: node.childNodes.filter(x => x.rawTagName).map(x => MapHtmlNodeToElement(x)) };
    if (node?.attributes?.allowLink !== undefined)
        obj.allowLink = true;
    if (node?.attributes?.backButton !== undefined)
        obj.backButton = true;
    if (node?.attributes?.isArray !== undefined)
        obj.isArray = true;
    if (node?.attributes?.editableTextarea !== undefined)
        obj.editableTextarea = true;
    if (node?.attributes?.logo !== undefined)
        obj.isLogo = true;

    if (node?.attributes?.pt !== undefined)
        obj.primary_text = true;
    if (node?.attributes?.st !== undefined)
        obj.secondary_text = true;
    if (node?.attributes?.tt !== undefined)
        obj.tertiary_text = true;

    if (node?.attributes?.pb !== undefined)
        obj.primary_bg = true;
    if (node?.attributes?.sb !== undefined)
        obj.secondary_bg = true;
    if (node?.attributes?.tb !== undefined)
        obj.tertiary_bg = true;

    if (node?.attributes?.imgContent !== undefined)
        obj.imgContent = node?.attributes?.imgContent;

    if (node?.attributes?.placeholder !== undefined)
        obj.placeholder = node?.attributes?.placeholder;

    if (node?.attributes?.af_type_index !== undefined)
        obj.af_type_index = node?.attributes?.af_type_index;
    if (node?.attributes?.af_type_count !== undefined)
        obj.af_type_count = node?.attributes?.af_type_count;

    let svgAttrs = ["xmlns", "fill", "viewBox", "stroke-width", "stroke", "stroke-linecap", "stroke-linejoin", "d", "id", "type", "rows"];
    for (let attr of svgAttrs) {
        if (node?.attributes[attr] !== undefined) {
            obj[attr] = node?.attributes[attr];
        }
    }

    return obj;
}