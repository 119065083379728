import { BoltIcon, ChevronLeftIcon, ChevronRightIcon, HomeIcon, LinkIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IKContext, IKImage } from "imagekitio-react";
import { useEffect, useState } from "react";
import Editable from "./components/editable";
import ImageEditor from "./components/image";
import LinkEditor from "./components/link-editor";

const EditableContent = ({ defaultContent, id, center, updateContent, page, textarea, readOnly, elementEditClick }) => {
    if (readOnly) return <>{page[id] ?? defaultContent}</>
    return <Editable elementEditClick={elementEditClick} _key={id} center={center} change={(val) => updateContent(val, id)} textarea={textarea}>{page[id] ?? defaultContent}</Editable>
}
let imageCounter = 0;
export default function TemplateRender(props) {
    const { elements, product } = props;
    const [defaultImages, setDefaultImages] = useState([]);
    const [modal, setModal] = useState()
    useEffect(() => {
        loadImages();
    }, [])

    const loadImages = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/image/placeholder/${encodeURI(props.product.defaultImage)}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("token")
            })
        }).then(res => { return res.json() }).then(x => {
            setDefaultImages(x.hits);
        })
    }
    imageCounter = 0;
    return <div className="relative h-full flex-1 min-h-full flex flex-col place-items-stretch">
        {modal && <div className="absolute inset-0 bg-black/40 z-20">
            <div className="p-5 flex flex-col h-full justify-end" onClick={() => setModal(false)}>
                <div className="bg-white rounded-lg w-full p-5" onClick={(e) => e.stopPropagation()}>
                    <div className="text-center text-neutral-800 font-semibold text-xl mb-5">{modal.title}</div>
                    <div className="whitespace-pre-wrap text-center text-neutral-600 text-lg">{modal.content}</div>
                    <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            onClick={() => setModal(false)}
                        >
                            {modal.buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>}
        {props.page.includeHomeButton && <div onClick={() => props.navigateHome ? props.navigateHome() : {}} className="absolute right-3 top-3 z-40"><HomeIcon className="w-10 h-10 cursor-pointer p-2 bg-white rounded-full"></HomeIcon></div>}
        <ElementList isFirst={true} {...props} setModal={setModal} defaultImages={defaultImages} elements={elements} i={''}></ElementList>
    </div>
}

const ElementList = (props) => {
    const { elements } = props;
    if (elements)
        return elements.map((x, i) => {
            if (x && x.isArray) {
                if (x?.element == "img") imageCounter++
                return <>
                    {
                        (props.page[x.id] ?? [{}]).map((y, i) => {
                            if (y.deleted) return null
                            return <div key={i} className={!props.readOnly ? 'border border-neutral-200' : ''}>
                                <div className="relative flex flex-row  select-none">
                                    {!props.readOnly && <div onClick={() => props.updateContent(props.page[x.id].map((x, j) => j == i ? { ...x, deleted: true } : x), x.id)} className="relative opacity-60 m-1 hover:opacity-80 right-0 w-6 rounded-full bg-gray-200 p-1 cursor-pointer text-gray-600"><TrashIcon></TrashIcon></div>}

                                    {!props.readOnly && x.af_type_count && <div className="flex items-center">
                                        <div onClick={() => props.updateContent(props.page[x.id].map((z, j) => j == i ? { ...z, type_index: z.type_index === undefined ? +z.af_type_count - 1 : +z.type_index > 0 ? +z.type_index - 1 : +x.af_type_count - 1 } : z), x.id)} className="relative opacity-60 hover:opacity-80 hover:bg-neutral-300 right-0 w-6 rounded-l-full bg-neutral-200 cursor-pointer text-gray-600 py-1 p-[2px]"><ChevronLeftIcon></ChevronLeftIcon></div>
                                        <div className="flex-1 bg-neutral-200 opacity-60 text-sm p-1">
                                            Change Format
                                        </div>
                                        <div onClick={() => props.updateContent(props.page[x.id].map((z, j) => j == i ? { ...z, type_index: z.type_index === undefined ? 1 : +z.type_index < +x.af_type_count - 1 ? +z.type_index + 1 : 0 } : z), x.id)} className="relative opacity-60 hover:opacity-80 hover:bg-neutral-300 right-0 w-6 rounded-r-full bg-neutral-200 cursor-pointer text-gray-600 py-1 p-[2px]"><ChevronRightIcon></ChevronRightIcon></div>

                                    </div>}
                                </div>
                                <Element array_type_index={y.type_index ?? 0} {...props} element={x} i={i > 0 ? i : undefined}></Element>
                            </div>
                        })
                    }
                    {!props.readOnly && <div className="w-full rounded-lg bg-black/5 p-2 cursor-pointer shadow-lg text-gray-800 text-center font-bold" onClick={() => props.updateContent([...props.page[x.id] ?? [{}], {}], x.id)}>Add +</div>}
                </>

            }
            else {
                if (x?.element == "img") imageCounter++;
                if (x && x.backButton && !props.isGenerating) {
                    return <div className="cursor-pointer" onClick={() => props.navigateBack ? props.navigateBack() : {}}>
                        <Element {...props} element={x}></Element>
                    </div>
                }
                if (x && x.allowLink && !props.isGenerating) {
                    return <LinkEditor key={x.id} {...props} onClick={() => props.page[x.id + '' + props.i]?.modal ? props.setModal(props.page[x.id + '' + props.i]) : props.navigateMockup ? props.navigateMockup(props.page[x.id + '' + props.i]) : {}} value={props.page[x.id + '' + props.i] ?? null} save={(value) => props.updateContent(value, x.id + props.i)}>

                        <Element {...props} element={x}></Element>

                    </LinkEditor>
                }
                else {
                    return <Element {...props} element={x}></Element>
                }
            }
        })
}

const getStyle = (pt, st, tt, pb, sb, tb, pc, sc, tc) => {
    let style = {};
    if (pt) style.color = pc;
    if (st) style.color = sc;
    if (tt) style.color = tc;

    if (pb) style.backgroundColor = pc;
    if (sb) style.backgroundColor = sc;
    if (tb) style.backgroundColor = tc;

    return style
}

const Element = (props) => {
    const { element, product } = props;
    if (!element) return null;
    if (props.array_type_index !== undefined && element.af_type_index !== undefined && +element.af_type_index != props.array_type_index) return null
    let CustomElement = element.element ? element.element : 'div';
    if (CustomElement == "img" && props.isGenerating) return <div className={'bg-neutral-400 ' + element.className}></div>
    if (CustomElement === "button") CustomElement = "div"
    if (CustomElement == "img" && element.isLogo && product.logoUrl && product.logoUrl.length > 0) return <IKContext
        urlEndpoint="https://ik.imagekit.io/verticode">
        <IKImage className={element.className} path={`${product.logoUrl}`} transformation={[{ width: 500 }]} />
    </IKContext>
    if (CustomElement == "img" && element.isLogo && !product.logoUrl) return <div className={element.className}><div className="rounded-full text-gray-400 border-2 border-gray-300 p-2 mx-auto h-20 w-20 "><BoltIcon></BoltIcon></div></div>

    if (CustomElement == "img" && props.page[element.id + '' + props.i]) return <ImageEditor {...props} change={(val) => props.updateContent(val, element.id + '' + props.i)}>
        <IKContext
            urlEndpoint="https://ik.imagekit.io/verticode">
            <IKImage className={element.className} path={`${props.page[element.id + '' + props.i]}`} transformation={[{ width: 500 }]} />
        </IKContext>
    </ImageEditor>

    if (CustomElement == "img" && !props.page[element.id + '' + props.i]) return <ImageEditor {...props} change={(val) => props.updateContent(val, element.id + '' + props.i)}><img src={props.defaultImages && props.defaultImages[imageCounter]?.webformatURL} className={element.className + ''}></img></ImageEditor>

    let attrs = ["xmlns", "fill", "viewBox", "stroke-width", "stroke", "stroke-linecap", "stroke-linejoin", "d", "placeholder", "type", "rows"];
    let attrsObj = {};
    for (let attr of attrs) {
        if (element[attr]) {
            attrsObj[attr] = element[attr];
        }
    }

    if (CustomElement == "img" || CustomElement == "input" || CustomElement == "textarea") return <CustomElement {...attrsObj} className={element.className + (props.readOnly ? '' : " pointer-events-none")} />
    return <CustomElement {...attrsObj} style={getStyle(element.primary_text, element.secondary_text, element.tertiary_text, element.primary_bg, element.secondary_bg, element.tertiary_bg, product.primaryColour, product.secondaryColour, product.tertiaryColour)} className={element.className + (props.isFirst ? ' flex-1' : '')}>
        {element.content && !props.isGenerating && <EditableContent textarea={element.editableTextarea} {...props} defaultContent={element.content.trim()} id={element.id + '' + props.i} />}
        {element.content && props.isGenerating && <span className="loader"></span>}
        <ElementList isFirst={false} {...props} elements={element.children} i={props.i}></ElementList>
    </CustomElement>
}