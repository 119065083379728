import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ChevronLeftIcon, CursorArrowRaysIcon, LinkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { usePopper } from 'react-popper'
import Button from './button';
import { Popover } from '@headlessui/react'
export default function LinkEditor(props) {
    const [open, setOpen] = useState(false);
    const [linkedPage, setLinkedPage] = useState(props.value)

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement)

    const navigate = useNavigate();

    if (props.readOnly) return <span className="cursor-pointer" onClick={props.onClick}>{props.children}</span>;

    return <>
        <div>
            <div className="relative" >
                <div className="absolute pointer-events-none inset-0 w-full h-full rounded-lg transition duration-200 hover:bg-blue-500/20 cursor-pointer text-gray-600" >
                    <Popover as="div" className="absolute right-0 inline-block text-left">
                        <Popover.Button ref={setReferenceElement}>
                            <LinkIcon className={`hover:scale-[1.04] hover:shadow-xl transform transition duration-200  pointer-events-auto cursor-pointer  w-8 h-8 absolute right-0 top-0 rounded-full p-1 ${!linkedPage ? 'bg-red-500/80' : 'bg-purple-500/80'}  hover:bg-purple-400 text-white`}></LinkIcon>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper} className="mt-2 absolute z-40 right-0 w-72 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {({ close }) => (<>
                                    {!linkedPage && <div className="px-1 py-1 pointer-events-auto">
                                        <div className="font-semibold text-lg p-1 text-neutral-800">Click Action</div>
                                        {linkedPage && <div onClick={() => { setLinkedPage(null); props.save(null); close() }}
                                            className={`hover:bg-purple-500 hover:text-white text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            No Action
                                        </div>}
                                        {!linkedPage && <div onClick={() => { props.save(null); close(); }}
                                            className={`bg-purple-500 text-white group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            No Action
                                        </div>}
                                        <div onClick={() => { setLinkedPage({ modal: false }); props.save({ modal: true }); }}
                                            className={`hover:bg-purple-500 hover:text-white text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            Link to a Page
                                        </div>
                                        <div onClick={() => { setLinkedPage({ modal: true }); props.save({ modal: true }); }}
                                            className={`hover:bg-purple-500 hover:text-white text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            Display a Popup
                                        </div>


                                    </div>}
                                    {linkedPage?.modal && <div className="px-1 py-1 pointer-events-auto">
                                        <div className="relative w-0 h-0" onClick={() => { setLinkedPage(null); props.save(null); }}><ChevronLeftIcon className="absolute w-6 h-6"></ChevronLeftIcon></div>
                                        <div className="p-1 text-left">
                                            <div className="text-center">Display a Popup</div>
                                            <hr className="my-1" />
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Title
                                                </label>
                                                <input value={linkedPage.title} onChange={(e) => setLinkedPage({ ...linkedPage, title: e.target.value })} className="my-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm" placeholder="Title..."></input>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Content
                                                </label>
                                                <textarea value={linkedPage.content} onChange={(e) => setLinkedPage({ ...linkedPage, content: e.target.value })} className="my-1 block w-full max-w-lg rounded-md border-gray-300 shadow-sm placeholder-gray-400  focus:border-green-500 focus:ring-green-500 sm:text-sm" placeholder="Content..." rows="3"></textarea>
                                                <label className="block text-sm font-medium text-gray-700 ">
                                                    Button Text
                                                </label>
                                                <input value={linkedPage.buttonText} onChange={(e) => setLinkedPage({ ...linkedPage, buttonText: e.target.value })} className="my-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm" placeholder="Button text..."></input>
                                                <Button text="Save" className="mt-3" onClick={() => { props.save(linkedPage); close() }}></Button>
                                            </div>
                                        </div>

                                    </div>}
                                    {linkedPage && !linkedPage?.modal && <div className="px-1 py-1 pointer-events-auto">
                                        <div className="relative w-0 h-0" onClick={() => { setLinkedPage(null); props.save(null); }}><ChevronLeftIcon className="absolute w-6 h-6"></ChevronLeftIcon></div>
                                        <div className="p-1 text-center">
                                            Link to a Page
                                            <hr className="my-1" />
                                            {props.product.pages.map(x => <>
                                                {linkedPage != x.title && <div onClick={() => { setLinkedPage(x.title); props.save(x.title); close() }}
                                                    className={`hover:bg-purple-500 hover:text-white text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}

                                                >
                                                    {x.title}
                                                </div>}
                                                {linkedPage == x.title && <div onClick={() => { setLinkedPage(x.title); props.save(x.title); close() }}
                                                    className={`bg-purple-500 text-white group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                >
                                                    {x.title}
                                                </div>}
                                            </>)}
                                        </div>

                                    </div>}
                                </>)}
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                </div>
                {props.children}
            </div>
        </div>

    </>
}
