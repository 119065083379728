import { useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize";

export default function Editable(props) {

    const [focused, setFocused] = useState(false);

    if (focused) {
        return <ReactTextareaAutosize autoFocus onBlur={() => setFocused(false)} key={props._key} style={{ minWidth:'0px', fontSize: 'inherit', textAlign: 'inherit', lineHeight: 'inherit', resize: 'none' }} className={`${props.center ? 'text-center' : ''} border border-blue-300 overflow-hidden bg-transparent p-0 m-0 w-full`} type="text" value={props.children} onChange={(e) => props.change(e.target.value)}></ReactTextareaAutosize>}

    return <div onClick={() => {setFocused(true); props.elementEditClick()}}  className="w-full border border-dashed transition duration-200 hover:bg-blue-500/20 cursor-pointer border-blue-100/50 whitespace-pre-wrap">{props.children}</div>
}