import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { IKCore } from "imagekitio-react"
import { v4 as uuid } from 'uuid';
import { IKContext, IKImage, IKUpload } from "imagekitio-react";

export default function ImageEditor(props) {
    const [open, _setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [images, setImages] = useState([]);
    const [tab, setTab] = useState(0)
    const navigate = useNavigate();

    const setOpen = (val) => {
        _setOpen(val)
        if (val) {
            fetch(`${process.env.REACT_APP_API_URL}/image/${encodeURI(props.product?.defaultImage)}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token")
                })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                setImages(x.hits)
            })
        }
    }

    if (props.readOnly) return props.children;

    const loadImages = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/image/${encodeURI(searchTerm)}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("token")
            })
        }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
            setImages(x.hits)
        })
    }

    const selectImage = (imgUrl) => {
        var imagekit = new IKCore({
            publicKey: "public_EoJWOyxRHK2FkPaJg0Y7alzsi2w=",
            urlEndpoint: "https://ik.imagekit.io/verticode",
            authenticationEndpoint: `${process.env.REACT_APP_API_URL}/public/image-auth`,
        });

        imagekit.upload({ file: imgUrl, fileName: uuid() }).then(result => {
            props.change(result.filePath);
        }).then(error => {
            console.log(error);
        });

    }

    const onSuccess = (res) => {
        props.change(res.filePath);
        setOpen(false)
    };

    return <>
        <div onClick={() => setOpen(true)} className="cursor-pointer">
            {props.children}
        </div>

        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-neutral-100 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="relative h-8">
                                    <div onClick={() => setOpen(false)} className="right-0 absolute h-4 w-4 float-right rounded-full border border-neutral-200 cursor-pointer text-neutral-800 hover:text-neutral-700">
                                    <XMarkIcon  ></XMarkIcon>
                                    </div>
                                   

                                </div>

                                <div>
                                    <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                                        <span onClick={() => setTab(0)}
                                            className={`${tab === 0 ? 'text-gray-900 ' : 'text-gray-500 hover:text-gray-700 '} cursor-pointer rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`}
                                        >
                                            <span>Search</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${tab === 0 ? 'bg-green-500 ' : 'bg-transparent '} absolute inset-x-0 bottom-0 h-0.5`}
                                            />
                                        </span>
                                        <span onClick={() => setTab(1)}
                                            className={`${tab === 1 ? 'text-gray-900 ' : 'text-gray-500 hover:text-gray-700 '} cursor-pointer rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`}
                                            aria-current={tab.current ? 'page' : undefined}
                                        >
                                            <span>Upload Your Own</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${tab === 1 ? 'bg-green-500 ' : 'bg-transparent '} absolute inset-x-0 bottom-0 h-0.5`}
                                            />
                                        </span>
                                    </nav>

                                    <div className="mt-3 text-center sm:mt-5">
                                        {tab === 0 && <>

                                            <form onSubmit={(e) => { e.preventDefault(); loadImages() }}>
                                                <div className="mt-5 flex">
                                                    <div className="flex-1">
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                value={searchTerm}
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                className="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                                placeholder="Search For An Image..."
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="mt-1 inline-flex w-full justify-center rounded-r-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                                                        >
                                                            Go
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>

                                            <div className=" grid grid-cols-4 gap-4 mt-5 bg-white rounded-md shadow-xl p-5">
                                                {images && images.length > 0 && images.map(x => <img className="hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer rounded-md shadow-xl h-20 object-cover m-auto" src={x.previewURL} onClick={() => { selectImage(x.largeImageURL); setOpen(false) }}></img>)}
                                            </div>

                                        </>}

                                        {tab === 1 && <>




                                            <label className="flex">
                                                <div className="mx-auto w-96">
                                                    <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                                        <div className="space-y-1 text-center">
                                                            <svg
                                                                className="mx-auto h-12 w-12 text-gray-400"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                viewBox="0 0 48 48"
                                                                aria-hidden="true"
                                                            >
                                                                <path
                                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                            <div className="flex text-sm text-gray-600">
                                                                <div
                                                                    htmlFor="file-upload"
                                                                    className="mx-auto relative cursor-pointer rounded-md  font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                                                >
                                                                    <div>Upload a file</div>
                                                                </div>
                                                            </div>
                                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 2MB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <IKContext
                                                    validateFile={file => file.size < 2000000}
                                                    publicKey="public_EoJWOyxRHK2FkPaJg0Y7alzsi2w="
                                                    urlEndpoint="https://ik.imagekit.io/verticode"
                                                    authenticationEndpoint={`${process.env.REACT_APP_API_URL}/public/image-auth`}
                                                >
                                                    <IKUpload className="absolute w-0 h-0 opacity-0"
                                                        onSuccess={onSuccess}
                                                    />
                                                </IKContext>
                                            </label>
                                        </>}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}