import { useNavigate } from "react-router-dom";
import logo from './logo.png'
import { useEffect, useState } from 'react'
import {
  BoltIcon, TagIcon,
} from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { IKContext, IKImage } from "imagekitio-react";
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'


export default function Products() {

  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setLoaded(true);
        setViewModel(x);
        if (!x.products || !x.products.length) navigate("/setup")
      })
    }
    fetchData();
  }, [])

  return <div className="flex flex-col min-h-screen">
    <div>
      <img
        className="h-20 w-auto mx-auto mt-8"
        src={logo}
      />
    </div>
    <main className="flex-1">
      <div className="mx-auto max-w-6xl py-6 sm:px-6 lg:px-8">

        {!loaded && <div>
          <svg aria-hidden="true" class="w-12 h-12 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
        </div>}

        {loaded && <div className="overflow-hidden">
          <ul role="list" className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {viewModel.products && viewModel.products.map((x) => (
              <li className="cursor-pointer bg-white  hover:opacity-80" key={x._id} style={{ background: x.primaryColour }}>
                <a onClick={() => navigate('/editor/' + x._id)} className="block">
                  <div className="flex flex-col pt-5">
                    {x.logoUrl && x.logoUrl.length > 0 && <IKContext
                      urlEndpoint="https://ik.imagekit.io/verticode">
                      <IKImage className="h-16 w-16 mx-auto" path={`${x.logoUrl}`} transformation={[{ width: 500 }]} />
                    </IKContext>}
                    {!x.logoUrl && <div style={{ color: x.secondaryColour }} className="h-16 w-16 mx-auto"><BoltIcon></BoltIcon></div>}
                  </div>


                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">

                      <div className="min-w-0 flex-1 px-4 text-center">
                        <div>
                          <p className="truncate text-xl font-semibold " style={{ color: x.secondaryColour }}>{x.name}</p>
                          <p className="mt-2 flex text-md opacity-80" style={{ color: x.secondaryColour }}>
                            <span className="truncate">{x.description}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
            <li className="cursor-pointer">
              <a onClick={() => navigate('/setup')} className="block bg-gray-100 hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0">
                      <div className="h-12 w-12 rounded-full text-gray-400 border-2 border-gray-300 p-2"><PlusIcon></PlusIcon></div>
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="truncate text-xl font-medium text-green-600">Add New</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>}

      </div>
    </main>
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden py-5 px-6 sm:py-12 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-4" aria-label="Footer">
          <div className="pb-6">
            <a href='https://verticode.co.uk' className="text-sm leading-6 text-gray-600 hover:text-gray-900">
              Verticode
            </a>
          </div>
          <div className="pb-6">
            <a href='https://verticode.co.uk' className="text-sm leading-6 text-gray-600 hover:text-gray-900">
              vixba
            </a>
          </div>
          <div className="pb-6">
            <a href='https://verticode.co.uk' className="text-sm leading-6 text-gray-600 hover:text-gray-900">
              Support
            </a>
          </div>
          <div className="pb-6">
            <a href='https://verticode.co.uk' className="text-sm leading-6 text-gray-600 hover:text-gray-900">
              Contact
            </a>
          </div>
        </nav>
        <div className="mt-5 flex justify-center space-x-10">
          <a href='https://www.linkedin.com/company/verticodemvp/' className="text-gray-400 hover:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
            </svg>
          </a>
        </div>
        <p className="mt-5 text-center text-xs leading-5 text-gray-500">
          &copy; 2023 Verticode Ltd. All rights reserved.
        </p>
      </div>
    </footer>
  </div>
}


