import { CheckIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";



export default function Guide({ steps, stepIndex }) {

    const [hideGuide, setHideGuide] = useState(localStorage.getItem("hg"))
    if (hideGuide) return <></>
    return <>
        <div className="bg-white shadow-xl rounded-md p-2">
            <XMarkIcon onClick={() => { setHideGuide(true); localStorage.setItem("hg", "t") }} className="h-4 w-4 float-right rounded-full border border-neutral-200 cursor-pointer text-neutral-500 hover:text-neutral-700"></XMarkIcon>

            <div className="text-center text-neutral-800 font-bold text-xl my-5">Help Guide</div>
            {steps.map((x, i) => <>
                {i != steps.length - 1 && <div className="flex mx-3 my-1">
                    <div className="shrink-0 mr-3 flex flex-col justify-center items-center">
                        {stepIndex > i && <div className="rounded-full p-[3px] w-3 h-3 bg-green-500 text-neutral-50"></div>}
                        {stepIndex === i && <div className="rounded-full w-5 h-5 bg-purple-500"></div>}
                        {stepIndex < i && <div className="rounded-full w-3 h-3 border-neutral-400 bg-neutral-200"></div>}
                    </div>
                    <div className="flex-1">
                        {stepIndex !== i && <div className="text-neutral-600 font-semibold text-xs">{x.title}</div>}
                        {stepIndex === i && <div className="text-neutral-800 font-semibold text-md mt-3">{x.title}</div>}
                        {stepIndex === i && <div className="text-neutral-600 text-sm mb-3">{x.description}</div>}
                    </div>
                </div>}
            </>)}
            {steps.length == stepIndex + 1 && <div onClick={() => { setHideGuide(true); localStorage.setItem("hg", "t") }} className="cursor-pointer hover:text-neutral-600 text-center text-neutral-800 font-semibold text-md my-3">Hide</div>}
        </div>
    </>
}