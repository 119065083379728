import { Link, useNavigate, useParams } from "react-router-dom";
import { IKContext, IKImage, IKUpload } from "imagekitio-react";
import { BoltIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function Settings({ onSave, onClose }) {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setLoaded(true);
        setProduct(x.product);
      })
    }
    fetchData();
  }, [])

  const onSuccess = (res) => {
    setProduct({ ...product, logoUrl: res.filePath });
  };

  const save = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      if (onSave) {
        onSave()
      }
      else {
        navigate("/product/" + params.id)
      }

    })
  }

  const onDelete = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token")
      })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      navigate('/')
    })
  }

  if (!loaded) return <></>
  return (
    <>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} confirm={() => onDelete()}></DeleteModal>
      <div className={`${onSave ? 'mx-auto max-w-3xl px-2 sm:px-2 lg:max-w-7xl lg:px-2' : 'mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8'}`}>
        <div className={`${onSave ? 'overflow-hidden' : 'overflow-hidden rounded-lg bg-white shadow'}`}>
          <div className="bg-white p-2 sm:p-6">
            <div className="sm:items-center">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">General Settings</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        These are the general settings that control the layout and look of your application.
                      </p>
                    </div>

                    <div className="space-y-6 sm:space-y-5">
                      
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Name
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              value={product.name}
                              onChange={(e) => setProduct({ ...product, name: e.target.value })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Description
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <textarea
                            rows={3}
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            value={product.description}
                            onChange={(e) => setProduct({ ...product, description: e.target.value })}
                          />
                          <p className="mt-2 text-sm text-gray-500">High level description of your product and it's aims.</p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Brand Colours
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="mt-3 flex flex-col">
                            <input
                              value={product.primaryColour}
                              onChange={(e) => setProduct({ ...product, primaryColour: e.target.value })}
                              type="color"
                              className="max-w-lg h-10 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-3 flex flex-col">
                            <input
                              value={product.secondaryColour}
                              onChange={(e) => setProduct({ ...product, secondaryColour: e.target.value })}
                              type="color"
                              className="max-w-lg h-10 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-3 flex flex-col">
                            <input
                              value={product.tertiaryColour}
                              onChange={(e) => setProduct({ ...product, tertiaryColour: e.target.value })}
                              type="color"
                              className="max-w-lg h-10 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>


                      <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="logo" className="block text-sm font-medium text-gray-700">
                          Logo
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex items-center max-w-lg">
                            {product.logoUrl && product.logoUrl.length > 0 && <IKContext
                              urlEndpoint="https://ik.imagekit.io/verticode">
                              <IKImage className="mx-auto h-20 w-20 rounded-full" path={`${product.logoUrl}`}  transformation={[{width: 500}]}  />
                            </IKContext>}
                            {!product.logoUrl && <div className="mx-auto h-20 w-20 rounded-full text-gray-400 border-2 border-gray-300 p-2"><BoltIcon></BoltIcon></div>}
                            <IKContext
                              publicKey="public_EoJWOyxRHK2FkPaJg0Y7alzsi2w="
                              urlEndpoint="https://ik.imagekit.io/verticode"
                              authenticationEndpoint={`${process.env.REACT_APP_API_URL}/public/image-auth`}
                            >
                              <IKUpload
                                onSuccess={onSuccess}
                              />
                            </IKContext>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5 flex">
                  <div className="flex-1">
                    <button
                      onClick={() => setDeleteOpen(true)}
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Delete
                    </button>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={() => { onClose ? onClose() : navigate('/product/' + params.id) }}
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => save()}
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



function DeleteModal({ open, setOpen, confirm }) {

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Delete Product
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this product? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => confirm()}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
