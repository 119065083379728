import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'

export default function LandingPage() {

  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setLoaded(true);
        if (!x.product.landingPageContent[0]) x.product.landingPageContent[0] = {}
        if (!x.product.landingPageContent[1]) x.product.landingPageContent[1] = {}
        setProduct(x.product);
      })
    }
    fetchData();
  }, [])

  const save = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      navigate("/product/" + params.id)
    })
  }

  if (!loaded) return <></>
  return (
    <>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="bg-white p-6">
            <div className="sm:items-center">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div>
                      <h3 className="text-xl font-medium leading-6 text-gray-900">Landing Page</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        If your product requires a landing page you can set that up here.
                      </p>
                    </div>

                    <div>
                      <h3 className="text- font-medium leading-6 text-gray-900">Hero</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        The main element at the top of your landing page
                      </p>
                    </div>

                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Heading
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              value={product.landingPageContent[0]?.header}
                              onChange={(e) => setProduct({ ...product, landingPageContent: [...product.landingPageContent.map((x, i) => i === 0 ? { ...x, header: e.target.value } : x)] })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Subheading
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <textarea
                            rows={3}
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            value={product.landingPageContent[0]?.subheader}
                            onChange={(e) => setProduct({ ...product, landingPageContent: [...product.landingPageContent.map((x, i) => i === 0 ? { ...x, subheader: e.target.value } : x)] })}
                          />
                        </div>
                      </div>

                    </div>

                    <div>
                      <h3 className="text- font-medium leading-6 text-gray-900">Product/Service</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        The second element on your page - describe your main service or product
                      </p>
                    </div>

                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Heading
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              value={product.landingPageContent[1]?.header}
                              onChange={(e) => setProduct({ ...product, landingPageContent: [...product.landingPageContent.map((x, i) => i === 1 ? { ...x, header: e.target.value } : x)] })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Subheading
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <textarea
                            rows={3}
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            value={product.landingPageContent[1]?.subheader}
                            onChange={(e) => setProduct({ ...product, landingPageContent: [...product.landingPageContent.map((x, i) => i === 1 ? { ...x, subheader: e.target.value } : x)] })}
                          />
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      onClick={() => navigate('/product/' + params.id)}
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => save()}
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
