import { useEffect, useState } from 'react'
import {
    PencilIcon,
    ArrowLeftIcon,
    ChevronLeftIcon,
} from '@heroicons/react/24/outline'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TemplateRender from './template-render'



export default function Preview({ live }) {

    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [loaded, setLoaded] = useState(false);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [history, setHistory] = useState([0])
    const [sParams, setSearchParams] = useSearchParams();
    const setPageIndex = (i) => {
        _setPageIndex(i)
    }

    const pageIndex = sParams.get("i") ?? 0;
    const _setPageIndex = (index) => {
        if (index === false || index === null || index === undefined) setSearchParams({})
        setSearchParams({ i: index });
    }
    useEffect(() => {
        async function fetchData() {
            if (!live) {
                await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': 'bearer ' + localStorage.getItem("token")
                    })
                }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                    setLoaded(true);
                    setProduct(x.product);
                })
            }
            else {
                await fetch(`${process.env.REACT_APP_API_URL}/public/product/${params.id}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': 'bearer ' + localStorage.getItem("token")
                    })
                }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                    setLoaded(true);
                    setProduct(x.product);
                })
            }

        }
        fetchData();
        if (searchParams.get('i')) setPageIndex(searchParams.get('i'))
    }, [])
    console.log(history)
    console.log(pageIndex)
    const navigateBack = () => {
        setPageIndex(history[history.length - 2]);
        setHistory([...history].filter((x, i) => i != history.length - 1))

    }

    const navigateHome = (page) => {
        let pi = product.pages.findIndex(x => x.isHome)
        if (pi === -1) {
            setPageIndex(0)
        }
        else {
            setPageIndex(pi);
        }
        setHistory([])
    }

    const navigateMockup = (page) => {
        let pi = product.pages.findIndex(x => x.title == page)
        if (pi === -1) {
            if (+pageIndex != product.pages.length - 1) {
                setPageIndex(+pageIndex + 1)
                setHistory(history.concat([+pageIndex + 1]))
            }
        }
        else {
            setPageIndex(pi);
            setHistory(history.concat([pi]))
        }
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    if (!loaded) return <div>
        <svg aria-hidden="true" className="mt-20 w-12 h-12 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
    </div>

    let page = product.pages[pageIndex];
    if (!page) {
        setPageIndex(0)
        return;
    }

    const pageTemplates = () => {
        return <div key={pageIndex} className="relative rounded-none sm:rounded-[4vh] border-neutral-800 sm:border-[5px] ss-font h-screen w-screen max-h-[100vh] sm:max-h-[667px] sm:w-[375px] sm:m-auto bg-white sm:shadow-neutral-900 sm:shadow-lg sm:h-[850px] overflow-y-auto hide-scroll">
            {/* <div className="absolute left-[98px] z-40 hidden sm:block">
          <div className="fixed bg-neutral-800 rounded-b-full h-6 w-44">

          </div>
        </div> */}
        <div className="absolute bottom-3 left-[138px] z-40  hidden sm:block">
          <div className="fixed bg-neutral-800 rounded-full h-1  w-24 z-40">

          </div>
        </div>
            
            
            {page.template && <TemplateRender navigateHome={navigateHome} elements={(page.altIndex !== null && page.altIndex !== undefined) ? page.template.alts[page.altIndex] : page.template.elements} product={product} page={product.pages[product.pages.indexOf(page)]} readOnly={true} navigateMockup={navigateMockup} navigateBack={navigateBack}></TemplateRender>}
        </div>
    }

    const header = () => {
        return <>

            {!live && <div onClick={() => { navigate('/editor/' + params.id + '?i=' + pageIndex) }} className="fixed z-50 top-2 right-5 cursor-pointer bg-purple-600 hover:bg-purple-700 rounded-lg shadow p-3"><PencilIcon className="text-neutral-200  w-8 h-8 inline"></PencilIcon><span className="text-neutral-200 hover:text-white text-lg font-semibold m-2 hidden sm:inline">Back To Edit</span></div>}
            {live && <div onClick={() => { navigate('/register') }} className="z-40 fixed top-1 right-1 sm:top-5 sm:right-5 text-gray-300 text-xs text-right p-2 bg-black/30 sm:bg-black/0 sm:p-0 rounded-md sm:text-sm">Prototype Made With <a className="underline text-gray-100" href="https://app.vixba.com">Vixba</a><br />Make Your Own For Free!</div>}


            <div className="fixed top-5 left-5 hidden sm:block w-[30%]">

                <div className="text-white text-3xl font-semibold">{product.name} <br /><span className=" text-gray-200 font-light text-2xl">Clickable Prototype</span></div>
                <div className="text-neutral-200 text-md max-w-lg pb-3 mt-2">{product.description} </div>

                {product.pages.filter((x, i) => i == pageIndex).map((x, i) => <div className="cursor-pointer mt-5">
                    <span className=" text-neutral-200 font-light text-xs">Current Page</span>
                    <div className={`text-neutral-50 text-2xl  font-semibold`}>{x.title}</div>
                    <div className={`text-gray-200 text-sm mb-4 `}>{x.description}</div>
                </div>)}
            </div>
        </>


    }
    return (<>

        {header()}
        <div className="h-screen bg-neutral-600 ss-font">

            <main className="sm:p-10 h-full w-full">
                {pageTemplates()}
            </main>
        </div>
    </>
    )
}
