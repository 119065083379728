import { Link } from "react-router-dom";

export default function Button(props) {

    const className = `cursor-pointer justify-center inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 ${props.className}`;

    if (props.link) {
        return <Link className={className} to={props.link}>{props.text}</Link>
    }
    return (
        <div className={className} onClick={() => props.onClick()}>
            {props.text}
        </div>
    )
}