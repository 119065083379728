import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import logo from './logo.png'
import { Fragment, useEffect, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
  AcademicCapIcon,
  BanknotesIcon,
  Bars3Icon,
  BellIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
  BoltIcon
} from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { IKContext, IKImage } from "imagekitio-react";

const actions = [
  {
    icon: ClockIcon,
    name: 'Request time off',
    href: '#',
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    icon: CheckBadgeIcon,
    name: 'Benefits',
    href: '#',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    icon: UsersIcon,
    name: 'Schedule a one-on-one',
    href: '#',
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    icon: BanknotesIcon,
    name: 'Payroll',
    href: '#',
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
  {
    icon: ReceiptRefundIcon,
    name: 'Submit an expense',
    href: '#',
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },
  {
    icon: AcademicCapIcon,
    name: 'Training',
    href: '#',
    iconForeground: 'text-green-700',
    iconBackground: 'bg-green-50',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Product() {

  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setLoaded(true);
        setProduct(x.product);
      })
    }
    fetchData();
  }, [])
  if (!loaded) return <></>
  return (
    <>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* Main 3 column grid */}
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            {/* Welcome panel */}
            <section aria-labelledby="profile-overview-title">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="bg-white p-6">
                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                      <div className="flex-shrink-0">
                        {product.logoUrl && product.logoUrl.length > 0 && <IKContext
                          urlEndpoint="https://ik.imagekit.io/verticode">
                          <IKImage className="mx-auto h-20 w-20" path={`${product.logoUrl}`}  transformation={[{width: 500}]}  />
                        </IKContext>}
                        {!product.logoUrl && <div className="mx-auto h-20 w-20 rounded-full text-gray-400 border-2 border-gray-300 p-2"><BoltIcon></BoltIcon></div>}
                      </div>
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-sm font-medium text-gray-400">Application</p>
                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">{product.name}</p>
                        <p className="text-sm font-medium text-gray-600">{product.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                  <div className="px-6 py-5 text-center text-sm font-medium">
                    <span className="text-gray-900">{product.models ? product.models.length : 0}</span>{' '}
                    <span className="text-gray-600">Data Models</span>
                  </div>
                  <div className="px-6 py-5 text-center text-sm font-medium">
                    <span className="text-gray-900">{product.pages ? product.pages.length : 0}</span>{' '}
                    <span className="text-gray-600">Pages</span>
                  </div>
                </div>
              </div>
            </section>

            <div className="text-gray-800 text-xl text-semibold">Pages</div>
            <section aria-labelledby="quick-links-title">
              <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">

                {product.pages.map((page, pageIdx) => (
                  <div
                    key={page.title}
                    className={classNames(
                      pageIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                      pageIdx === 1 ? 'sm:rounded-tr-lg' : '',
                      pageIdx === product.pages.length - 2 ? 'sm:rounded-bl-lg' : '',
                      pageIdx === product.pages.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                      'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500'
                    )}
                  >
                    {/* <div>
                      <span
                        className={classNames(
                          action.iconBackground,
                          action.iconForeground,
                          'rounded-lg inline-flex p-3 ring-4 ring-white'
                        )}
                      >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                      </span>
                    </div> */}
                    <div className="mt-8">
                      <h3 className="text-lg font-medium">
                        <Link to={`/product/pages/${params.id}`} className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span className="absolute inset-0" aria-hidden="true" />
                          {page.title}
                        </Link>
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        {page.description}
                      </p>
                    </div>
                    <span
                      className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <section>
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="p-6">

                  <div className="">
                    <Link
                      to={`/editor/${params.id}`}
                      className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      Back To Editor
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="p-6">
                  <h2 className="text-base font-medium text-gray-900">
                    Data Models
                  </h2>
                  <div className="mt-6 flow-root">
                    <ul role="list" className="-my-5 divide-y divide-gray-200">
                      {product.models.map((model) => (
                        <li key={model.id} className="py-5">
                          <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                            <h3 className="text-sm font-semibold text-gray-800">
                              <span>
                                {model.name}
                              </span>
                            </h3>
                            <p className="mt-1 text-sm text-gray-600 line-clamp-2">{model.preview}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-6">
                    <Link
                      to={`/product/models/${params.id}`}
                      className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      View all
                    </Link>
                  </div>
                </div>
              </div>
            </section>



          </div>
        </div>
      </div>
    </>
  )
}
