import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { IKContext, IKUpload } from "imagekitio-react";
import { TwitterPicker } from 'react-color';
import { PencilIcon } from "@heroicons/react/24/outline";
import logo from './logo-dark.png'

export default function Setup() {

  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [displayedElements, setDisplayedElements] = useState(0);

  const [page, setPage] = useState(0);
  const [colourSchemes, setColourSchemes] = useState([]);
  const [id, setId] = useState(null)
  const [templates, setTemplates] = useState([]);
  const [content, setContent] = useState([]);
  const [colourEditOpen, setColourEditOpen] = useState([])

  const [loadingPages, setLoadingPages] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  const [editingColourScheme, setEditingColourScheme] = useState(false)

  const onSuccess = (res) => {
    setValues({ ...values, logoUrl: res.filePath });
  };

  useEffect(() => {

    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/setup-templates`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        if (x && x.templates)
          setTemplates(x.templates)
      })
    }
    fetchData();


  }, []);

  const loadPage2 = async () => {
    setPage(1);
    await loadColourSchemes();
  }

  const loadPage3 = () => {
    setPage(2);
    loadSuggestedPages();
  }

  const loadPage4 = () => {

    let variables = [];
    for (let page of templates) {
      if (values.includedPages.includes(page.name)) {
        for (let v of page.variables) {
          variables.push(v)
        }
      }
    }

    loadSuggestedContent(variables)
    setPage(3);

  }

  const submit = async () => {
    setPage(100);
    await fetch(`${process.env.REACT_APP_API_URL}/product`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: values, content: content })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      navigate('/editor/' + x.id + '?i=1&n=n')
    })
  }

  const loadColourSchemes = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/language/colours/${values.name}`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token")
      })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      setColourSchemes(x)
      setValues({ ...values, primaryColour: x[0][0], secondaryColour: x[0][1], tertiaryColour: x[0][2] })
    })
  }

  const loadSuggestedPages = async () => {
    setLoadingPages(true)
    await fetch(`${process.env.REACT_APP_API_URL}/language/pages`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ description: values.description })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      setValues({ ...values, includedPages: x })
      setLoadingPages(false)
    })

  }

  const loadSuggestedContent = async (variables) => {
    setLoadingContent(true)


    await fetch(`${process.env.REACT_APP_API_URL}/product`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: values, variables: variables })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      navigate('/editor/' + x.id)
    })
  }

  return (
    <>
      <div className="min-h-screen flex flex-col text-center bg-white">
        <img src={logo} className="absolute left-[calc(50%-3rem)] top-6 w-24 sm:left-6"></img>
        {page === 100 && <div className="m-0 sm:m-14 flex-1 p-5 max-w-6xl mx-auto">
          <div className="flex">
            <div className="w-28 mx-auto mt-20">
              <svg aria-hidden="true" class="mr-2 w-28 h-28 text-neutral-200 animate-spin dark:text-neutral-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            </div>

          </div>
        </div>}

        {page === 0 && <div className="flex-1 p-5 h-full flex flex-col">
          <div className="flex flex-col flex-1">
            <div className="flex-1 mt-24">
              <div className="text-4xl text-neutral-800 font-bold mb-10 mt-12">Welcome To <span className="text-green-400">Vixba</span>!</div>
              <div className="text-2xl text-neutral-800 mb-10">Start building your prototype by filling out a few details about your app idea</div>
              <div className=" ">
                <label htmlFor="email" className="block text-sm font-medium text-neutral-700">
                  What's Your Product Called? <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 flex flex-col">
                  <input
                    value={values.name}
                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                    type="text"
                    className="mx-auto max-w-xl p-3 block w-full rounded-md border-neutral-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    placeholder="Eg. Paw Floor"
                  />
                </div>
              </div>
              <div className="mt-5">
                <label htmlFor="email" className="block text-sm font-medium text-neutral-700">
                  What is the purpose of your product? <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 flex flex-col">
                  <textarea
                    value={values.description}
                    onChange={(e) => setValues({ ...values, description: e.target.value })}
                    rows="5"
                    className="mx-auto max-w-xl p-3 block w-full rounded-md border-neutral-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    placeholder="Eg. A two sided marketplace for dog owners to book walks from local dog walking services. Dog owners can search for local dog walkers and book walks directly through the website..."
                  />
                </div>
                <p className="text-neutral-400 text-sm">I will use this description to make suggestions for your prototype.</p>
              </div>

            </div>
            <div className="mt-10">
              {values.name && values.description && <div onClick={() => loadPage2()} className="cursor-pointer w-44 mx-auto items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Next
              </div>}
              {(!values.name || !values.description) && <div className="w-44 mx-auto items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-neutral-200 ">
                Next
              </div>}
            </div>
            <nav className="flex items-center justify-center mt-5" aria-label="Progress">
              <p className="text-sm font-medium">
                Step 1 of 2
              </p>
              <ol role="list" className="ml-8 flex items-center space-x-5">
                <li >
                  <span className="relative flex items-center justify-center" aria-current="step">
                    <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                      <span className="h-full w-full rounded-full bg-green-200" />
                    </span>
                    <span className="relative block h-2.5 w-2.5 rounded-full bg-green-600" aria-hidden="true" />
                  </span>
                </li>
                <li>
                  <span className="block h-2.5 w-2.5 rounded-full bg-neutral-200 hover:bg-neutral-400">
                  </span>
                </li>
              </ol>
            </nav>
          </div>

        </div>}
        {page === 1 && <div className="flex-1 p-5 flex flex-col">
          <div className="flex-1 mt-24">
            <div className="text-2xl text-neutral-800 mb-10 font-semibold">Based on your app description, these colour schemes will work well!</div>

            <div className="max-w-3xl mx-auto">

              {!colourSchemes.length && <>
                <div className="text-sm text-neutral-500">Generating a colour scheme based on your idea...</div>
                <div>
                  <svg aria-hidden="true" class="w-12 h-12 m-auto text-neutral-200 animate-spin dark:text-neutral-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
              </>}
              {colourSchemes.length > 0 && <div className="text-md font-light text-neutral-800 mb-2">Click on the colour scheme you like the most or select custom colours if you have something specific in mind.</div>}
              {colourSchemes.map(scheme => <>
                <div className={`mt-1 flex cursor-pointer rounded ${(scheme[0] === values.primaryColour && scheme[1] === values.secondaryColour && scheme[2] === values.tertiaryColour) ? 'h-20 border border-sky-400' : 'h-10'}`} onClick={() => setValues({ ...values, primaryColour: scheme[0], secondaryColour: scheme[1], tertiaryColour: scheme[2] })}>
                  {scheme.map(x => <span className="flex-1 rounded shadow m-1" style={{ background: x }}></span>)}
                </div>
              </>)}
              {colourSchemes.length > 0 && <>
                {!editingColourScheme && <div onClick={() => setEditingColourScheme(true)} className="text-lg cursor-pointer hover:text-neutral-800 text-neutral-600 font-medium mt-4"><PencilIcon className="w-4 h-4 inline"></PencilIcon>Customise Colours</div>}
                {editingColourScheme && <div className={`mt-1 flex cursor-pointer h-20`} >
                  <span onClick={() => colourEditOpen.includes(1) ? setColourEditOpen(colourEditOpen.filter(x => x !== 1)) : setColourEditOpen(colourEditOpen.concat([1]))} className="flex-1 m-2 rounded" style={{ background: values.primaryColour }}><PencilIcon className="w-6 h-6 text-neutral-400 m-auto mt-4"></PencilIcon>
                    {colourEditOpen.includes(1) && <div onClick={(e) => e.stopPropagation()} className="absolute"><TwitterPicker color={values.primaryColour} onChangeComplete={(c) => setValues({ ...values, primaryColour: c.hex })}></TwitterPicker></div>}
                  </span>
                  <span onClick={() => colourEditOpen.includes(2) ? setColourEditOpen(colourEditOpen.filter(x => x !== 2)) : setColourEditOpen(colourEditOpen.concat([2]))} className="flex-1 m-2 rounded" style={{ background: values.secondaryColour }}><PencilIcon className="w-6 h-6 text-neutral-400 m-auto mt-4"></PencilIcon>
                    {colourEditOpen.includes(2) && <div onClick={(e) => e.stopPropagation()} className="absolute"><TwitterPicker color={values.secondaryColour} onChangeComplete={(c) => setValues({ ...values, secondaryColour: c.hex })}></TwitterPicker></div>}
                  </span>
                  <span onClick={() => colourEditOpen.includes(3) ? setColourEditOpen(colourEditOpen.filter(x => x !== 3)) : setColourEditOpen(colourEditOpen.concat([3]))} className="flex-1 m-2 rounded" style={{ background: values.tertiaryColour }}><PencilIcon className="w-6 h-6 text-neutral-400 m-auto mt-4"></PencilIcon>
                    {colourEditOpen.includes(3) && <div onClick={(e) => e.stopPropagation()} className="absolute"><TwitterPicker color={values.tertiaryColour} onChangeComplete={(c) => setValues({ ...values, tertiaryColour: c.hex })}></TwitterPicker></div>}
                  </span>
                </div>}

              </>}
            </div>

            <div><label className="block text-lg text-neutral-800 mt-6">
              If you've have a logo, upload it here
            </label>

              <div className="mt-5">
                <label>
                  <div className="cursor-pointer justify-center inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                    Upload Logo
                  </div>
                  <IKContext
                    publicKey="public_EoJWOyxRHK2FkPaJg0Y7alzsi2w="
                    urlEndpoint="https://ik.imagekit.io/verticode"
                    authenticationEndpoint={`${process.env.REACT_APP_API_URL}/public/image-auth`}
                  >
                    <IKUpload className="absolute w-0 h-0 opacity-0"
                      onSuccess={onSuccess}
                    />
                  </IKContext>
                </label>

              </div>
            </div>
          </div>

          <div className="mt-10">
            {values.primaryColour && values.secondaryColour && values.tertiaryColour && <div onClick={() => submit()} className="cursor-pointer w-44 mx-auto items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              Create My App
            </div>}
            {(!values.primaryColour || !values.secondaryColour || !values.tertiaryColour) && <div className="w-44 mx-auto items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-neutral-200 ">
              Create My App
            </div>}
          </div>
          <nav className="flex items-center justify-center mt-5" aria-label="Progress">
            <p className="text-sm font-medium">
              Step 2 of 2
            </p>
            <ol role="list" className="ml-8 flex items-center space-x-5">
              <li >
                <span className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900">
                </span>
              </li>
              <li >
                <span className="relative flex items-center justify-center" aria-current="step">
                  <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                    <span className="h-full w-full rounded-full bg-green-200" />
                  </span>
                  <span className="relative block h-2.5 w-2.5 rounded-full bg-green-600" aria-hidden="true" />
                </span>
              </li>
            </ol>
          </nav>
        </div>}
        {/* {page === 2 && <div className="flex-1 p-5 flex flex-col ">
          <div className="flex-1 max-w-6xl mx-auto mt-24">
            <div className="text-2xl text-neutral-800 mb-6 mt-10">Based on your description I think you need these pages.</div>
            <div className="text-2xl text-neutral-800 mb-10">Feel free to add or remove pages if I've got this wrong (I'm still learning). You can always add or remove pages later on!</div>
            {loadingPages && <>
              <div className="text-sm text-neutral-500">Thinking about what pages your product needs...</div>
              <div>
                <svg aria-hidden="true" class="w-12 h-12 m-auto text-neutral-200 animate-spin dark:text-neutral-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </div>
            </>}
            {!loadingPages && <div className="grid grid-cols-2">
              <div className="m-3 rounded bg-neutral-50 shadow">
                <div className="text-xl text-neutral-800 font-semibold">Pages</div>
                {templates.filter(x => !values.includedPages?.includes(x.name)).map(x => <div onClick={() => setValues({ ...values, includedPages: values.includedPages.concat([x.name]) })} className="m-2 p-2 rounded bg-neutral-100 hover:bg-green-100 cursor-pointer">{x.name}</div>)}
              </div>
              <div className="m-3 rounded bg-neutral-50 shadow">
                <div className="text-xl text-neutral-800 font-semibold">Your Included Pages</div>
                {values.includedPages?.map(x => <div onClick={() => setValues({ ...values, includedPages: values.includedPages.filter(y => y != x) })} className="m-2 p-2 rounded bg-green-100 hover:bg-neutral-100 cursor-pointer">{x}</div>)}
              </div>
            </div>}
          </div>
          <div className="mt-10">
            {!loadingPages && <div onClick={() => loadPage4()} className="cursor-pointer w-44 mx-auto items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              Next
            </div>}
          </div>
          <nav className="flex items-center justify-center mt-5" aria-label="Progress">
            <p className="text-sm font-medium">
              Step 3 of 4
            </p>
            <ol role="list" className="ml-8 flex items-center space-x-5">
              <li >
                <span className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900">
                </span>
              </li>
              <li >
                <span className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900">
                </span>
              </li>
              <li >
                <span className="relative flex items-center justify-center" aria-current="step">
                  <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                    <span className="h-full w-full rounded-full bg-green-200" />
                  </span>
                  <span className="relative block h-2.5 w-2.5 rounded-full bg-green-600" aria-hidden="true" />
                </span>
              </li>
              <li>
                <span className="block h-2.5 w-2.5 rounded-full bg-neutral-200 hover:bg-neutral-400">
                </span>
              </li>
            </ol>
          </nav>
        </div>}
        {page === 3 && <div className="flex-1 p-5 flex flex-col ">
          <div className="flex-1 max-w-6xl mx-auto mt-24">
            <div className="text-2xl text-neutral-800 mb-6 mt-10">Great! Now I just need to generate some content for your product.</div>
            <div className="text-2xl text-neutral-800 mb-10">You will be able to update and fine tune everything later!</div>
            <div className="text-2xl text-neutral-800 mb-10">This could take up to 1 minute.</div>
            {loadingContent && <>
              <div className="text-sm text-neutral-500">Writing some content for your prototype...</div>
              <div>
                <svg aria-hidden="true" class="w-12 h-12 m-auto text-neutral-200 animate-spin dark:text-neutral-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </div>
            </>}
            {!loadingContent && <>
              <div className="text-sm text-neutral-500">Done!</div>
            </>}
            {!loadingContent && <div>
              {content.map(x => <div className="text-left mt-3">

                <div>
                  <label className="block max-w-xl mx-auto text-sm font-medium text-neutral-700">
                    {x.prompt}
                  </label>
                  <div className="mt-1">
                    <input
                      value={x.value}
                      onChange={(e) => setContent(content.map(y => y === x ? { ...x, value: e.target.value } : y))}
                      type="text"
                      className="mx-auto max-w-xl p-3 block w-full rounded-md border-neutral-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>)}
            </div>} 
          </div>
           {!loadingContent && <div className="mt-10">
            <div onClick={() => submit()} className="cursor-pointer w-44 mx-auto items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              Finish
            </div>
          </div>}
          <nav className="flex items-center justify-center mt-5" aria-label="Progress">
            <p className="text-sm font-medium">
              Step 4 of 4
            </p>
            <ol role="list" className="ml-8 flex items-center space-x-5">
              <li >
                <span className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900">
                </span>
              </li>
              <li >
                <span className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900">
                </span>
              </li>
              <li >
                <span className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900">
                </span>
              </li>
              <li >
                <span className="relative flex items-center justify-center" aria-current="step">
                  <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                    <span className="h-full w-full rounded-full bg-green-200" />
                  </span>
                  <span className="relative block h-2.5 w-2.5 rounded-full bg-green-600" aria-hidden="true" />
                </span>
              </li>
            </ol>
          </nav>
        </div>} */}
        <div className="flex-row text-neutral-400 text-md mx-14 hidden sm:flex"><div className="flex-1 text-left pb-10 pr-16">vixba</div></div>

      </div>

    </>
  )
}
