import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import {
  CheckIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/outline'


export default function Upgrade() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  useEffect(() => {

  }, []);;

  const isMobile = width <= 768;
  const navigate = useNavigate();
  const params = useParams();
  const [sParams, setSearchParams] = useSearchParams();

  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/permissions`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setPermissions(x.permissions);
      })

      if (localStorage.getItem("token")) {
        await fetch(`${process.env.REACT_APP_API_URL}/product/upgrade-click`, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'bearer ' + localStorage.getItem("token")
          })
        })
      }

    }
    fetchData();
  }, [])
  const select = async (currency) => {
    await fetch(`${process.env.REACT_APP_API_URL}/payment/subscribe/${currency}`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token")
      })
    }).then(res => { return res.json() }).then(x => {
      window.location.replace(x.url)
    })
  }

  return <Pricing isPro={permissions.isPro} upgrade={(currency) => select(currency)}></Pricing>
}
const tiers = [
  {
    name: 'Founder',
    id: 'founder',
    href: '/register',
    priceMonthly_uk: '£25',
    priceMonthly_us: '$30',
    description: 'Perfect for an individual founder to build a professional mockup for their app idea.',
    features: ['Easy To Use No-Code Editor', '5 Products', 'Access Pro Templates', 'Unlimited Pages', 'Share With Customers, Users, or Investors'],
  },
  {
    name: 'Startup',
    id: 'startup',
    href: '#',
    priceMonthly_uk: '£60',
    priceMonthly_us: '$70',
    description: 'Built for start-up teams ready to develop their app. Get access to your code & advanced support to kick-start your product build. Contact us at hey@vixba.com to upgrade to startup tier.',
    features: [
      'Everything In Founder',
      'Unlimited Products',
      'Code Download',
      'Advanced Support',
      'Custom Domain',
    ],
  },
]

function Pricing({ upgrade, isPro }) {
  let navigate = useNavigate();
  const [inUs, setInUs] = useState(true);
  return (
    <div className="isolate overflow-hidden bg-neutral-800">
      <div onClick={() => navigate(-1)} className="absolute left-10 top-10"><ChevronLeftIcon className="text-neutral-200 w-8 h-8 cursor-pointer"></ChevronLeftIcon></div>
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-green-500">Subscribe</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Upgrade Your Subscription To Continue Building Your Mockup
          </p>
        </div>
        <div className="relative mt-6">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
            Only pay for what you need, upgrade only when you need it
          </p>
          {!inUs && <p onClick={() => setInUs(true)} className="underline mx-auto mt-3 max-w-1xl cursor-pointer leading-8 text-white text-md">
            I'm In The USA
          </p>}
          {inUs && <p onClick={() => setInUs(false)} className="underline mx-auto mt-3 max-w-1xl cursor-pointer leading-8 text-white text-md">
            I'm Not In The USA
          </p>}
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse cx={604} cy={512} fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx={604} ry={512} />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map((tier, i) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3 id={tier.id} className="text-base font-semibold leading-7 text-green-600">
                      {tier.name}
                    </h3>
                    {i === 0 && <div className="text-3xl font-bold tracking-tight text-gray-900">7 Day Free Trial</div>}
                    {i === 0 && <div className="text-sm font-bold tracking-tight text-gray-500">then</div>}
                    <div className="mt-4 flex items-baseline gap-x-2">

                      <span className="text-5xl font-bold tracking-tight text-gray-900">{inUs ? tier.priceMonthly_us : tier.priceMonthly_uk}</span>
                      <span className="text-base font-semibold leading-7 text-gray-600">/month</span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">{tier.description}</p>
                    <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon className="h-6 w-5 flex-none text-green-600" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {i === 0 && !isPro && <div
                    onClick={() => upgrade(inUs ? 'usd' : 'gbp')}
                    className="mt-8 block rounded-md bg-green-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Upgrade
                  </div>}
                  {i === 0 && isPro && <div
                    className="mt-8 block rounded-md bg-gray-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Current Subscription
                  </div>}
                  {i === 1 && <a
                    href={'mailto:hey@vixba.com'}
                    className="mt-8 block rounded-md bg-green-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Contact Us To Upgrade To Startup
                  </a>}
                </div>
              ))}
              <div className="flex flex-col items-start gap-y-6 gap-x-8 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                <div className="lg:min-w-0 lg:flex-1">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-green-600">Enterprise</h3>
                  <p className="mt-1 text-base leading-7 text-gray-600">
                    Custom prototyping solution for large teams with advanced use-cases.
                  </p>
                </div>
                <a
                  href="https://calendly.com/vixba/30min" target="_blank" rel="noreferrer"
                  className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-green-600 ring-1 ring-inset ring-green-200 hover:ring-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Talk To Us <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
