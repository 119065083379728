import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';


export default function Cancel() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  const navigate = useNavigate();

  return (
    <div className="isolate overflow-hidden bg-neutral-800">
      <div className="mx-auto h-screen overflow-y-scroll hide-scroll max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-green-500">Upgrade</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            You've not completed your subscription
          </p>
        </div>
        <div className="relative mt-6">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
            Vixba is a great tool to build your app mockup and begin your startup journey. We're here to support you in your mockup build and beyond.<br />
            <div className="bg-green-600 inline-block rounded-md shadow cursor-pointer text-white p-4 my-12 px-8" onClick={() => navigate("/upgrade")}>Try Again</div>
          </p>
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse cx={604} cy={512} fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx={604} ry={512} />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-12">
          <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">

            <div className="flex flex-col items-start gap-y-6 gap-x-8 rounded-3xl p-8 ring-1 ring-gray-200/20 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-green-600">Collaborate</h3>
                <p className="mt-1 text-base leading-7 text-gray-100">
                  Book a collaboration session to kick start building your mockup
                </p>
              </div>
              <a
                href="https://calendly.com/vixba/30min" target="_blank" rel="noreferrer"
                className="sm:mx-0 mx-auto rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-green-600 ring-1 ring-inset ring-green-200 hover:ring-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Book A Session <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
